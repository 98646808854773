import request from '@/utils/request'

export default {
  // 获取学历接口
  getEducation(){
    return request({
      url: '/taskBook/getEducation',
      method: 'get'
    })
  }
}
